<template>
  <el-card id="contract">
    <header>
      <!-- <h1>合同管理--新版本</h1> -->
      <!-- <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>合同金额</span>
            <el-input
              v-model="contractMoney"
              placeholder="请输入合同金额"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>客户签约人</span>
            <el-input
              v-model="clientUser"
              placeholder="请输入客户签约人"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>公司签约人</span>
            <el-input
              v-model="companyUser"
              placeholder="请输入公司签约人"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>客户名称</span>
            <el-input
              v-model="clientName"
              placeholder="请输入客户名称"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>合同名称</span>
            <el-input
              v-model="contractName"
              placeholder="请输入合同名称"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span style="width: 16%">创建时间</span>
            <el-date-picker
              v-model="beginTime"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="mini"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="mini"
            class="searchBtn"
            >查找</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="mini"
            class="reset"
            >重置</el-button
          >
        </div>
      </div> -->
    </header>
    <hr>
    <main>
      <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
        @row-dblclick="dblclick">
        <el-table-column type="selection" width="55" align="center"></el-table-column>

        <el-table-column prop="companyName" label="公司名称" width="200" align="center" fixed></el-table-column>
        <el-table-column prop="customerSource" label="客户来源" width="200" align="center"></el-table-column>
        <el-table-column prop="type" label="行业类型" width="200" align="center"></el-table-column>
        <el-table-column prop="createUserName" label="创建人" width="100" align="center"></el-table-column>
        <el-table-column prop="phone" width="100" label="联系人" align="center"></el-table-column>
        <el-table-column prop="telephone" width="120" label="电话" align="center"></el-table-column>
        <el-table-column prop="businessHandling" width="80" label="产品类型" align="center"></el-table-column>
        <el-table-column prop="businessType" label="业务类型" width="80" align="center"></el-table-column>
        <el-table-column prop="routeType" label="线路类型" width="80" align="center"></el-table-column>
        <el-table-column prop="numberFee" label="号码费" width="80" align="center"></el-table-column>
        <el-table-column prop="numberNum" label="号码数量" width="80" align="center"></el-table-column>
        <el-table-column prop="combo" label="套餐(月)" width="80" align="center"></el-table-column>
        <el-table-column prop="seatsNumber" label="坐席数量" width="80" align="center"></el-table-column>
        <el-table-column prop="rateWithin" label="套餐内费率" width="100" align="center"></el-table-column>
        <el-table-column prop="rateExtra" label="套餐外费率" width="100" align="center"></el-table-column>
        <el-table-column prop="comboRecharge" label="套餐外充值" width="100" align="center"></el-table-column>
        <el-table-column prop="cooperationTime" label="合作周期(月)" width="120" align="center"></el-table-column>
        <el-table-column prop="featureFee" label="功能费" width="80" align="center"></el-table-column>
        <el-table-column prop="feeTotal" label="费用合计" width="100" align="center"></el-table-column>
        <el-table-column prop="presented" label="赠送" width="80" align="center"></el-table-column>
        <el-table-column prop="installment" label="是否分期" width="100" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="160" align="center"></el-table-column>
        <el-table-column prop="remarke" label="备注" width="160" align="center"></el-table-column>

        <el-table-column prop="auditStatus" label="状态" align="center" width="100">
          <template #default="scope">
            <span v-if="scope.row.auditStatus == 1">审核中</span>
            <span v-if="scope.row.auditStatus == 2">审核通过</span>
            <span v-if="scope.row.auditStatus == 3">审核未通过</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center" fixed="right">
          <template #default="scope">
            <el-button type="primary" @click="update(scope.row)" size="mini">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </main>

    <!-- 编辑合同弹出框 -->
    <el-dialog title="编辑合同" :visible.sync="updateVisible" width="70%">
      <el-form ref="sjyForm" :model="sjyForm" label-width="100px">
        <div class="contract">
          <el-form-item label="公司名称">
            <el-input v-model="sjyForm.companyName" placeholder="请输入公司名称" disabled></el-input>
          </el-form-item>
          <el-form-item label="行业类型">
            <el-select v-model="sjyForm.type" placeholder="请选择行业类型" filterable>
              <el-option v-for="item in typeList" :key="item.id" :label="item.title" :value="item.title">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系人">
            <el-input v-model="sjyForm.phone" placeholder="请输入联系人"></el-input>
          </el-form-item>
          <el-form-item label="电话">
            <el-input v-model="sjyForm.telephone" placeholder="请输入电话"></el-input>
          </el-form-item>
          <el-form-item label="产品类型">
            <el-select v-model="sjyForm.businessHandling" placeholder="请选择产品类型" filterable>
              <el-option v-for="item in productTypeList" :key="item.id" :label="item.title" :value="item.title">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="业务类型">
            <el-select v-model="sjyForm.businessType" placeholder="请选择业务类型" filterable>
              <el-option v-for="item in businessTypeList" :key="item.id" :label="item.title" :value="item.title">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="线路类型">
            <el-select v-model="sjyForm.routeType" placeholder="请选择线路类型" filterable>
              <el-option v-for="item in circuitTypeList" :key="item.id" :label="item.title" :value="item.title">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="号码费">
            <el-input v-model="sjyForm.numberFee" placeholder="请输入号码费"></el-input>
          </el-form-item>
          <el-form-item label="号码数量">
            <el-input v-model="sjyForm.numberNum" placeholder="请输入号码数量"></el-input>
          </el-form-item>
          <el-form-item label="套餐(月)">
            <el-input v-model="sjyForm.combo" placeholder="请输入套餐(月)"></el-input>
          </el-form-item>
          <el-form-item label="坐席数量">
            <el-input v-model="sjyForm.seatsNumber" placeholder="请输入坐席数量"></el-input>
          </el-form-item>
          <el-form-item label="套餐内费率">
            <el-input v-model="sjyForm.rateWithin" placeholder="请输入套餐内费率"></el-input>
          </el-form-item>
          <el-form-item label="套餐外费率">
            <el-input v-model="sjyForm.rateExtra" placeholder="请输入套餐外费率"></el-input>
          </el-form-item>
          <el-form-item label="合作周期(月)">
            <el-input v-model="sjyForm.cooperationTime" placeholder="请输入合作周期(月)"></el-input>
          </el-form-item>
          <el-form-item label="套餐外充值">
            <el-input v-model="sjyForm.comboRecharge" placeholder="请输入套餐外充值"></el-input>
          </el-form-item>
          <el-form-item label="功能费">
            <el-input v-model="sjyForm.featureFee" placeholder="请输入功能费"></el-input>
          </el-form-item>
          <el-form-item label="费用合计">
            <el-input v-model="sjyForm.feeTotal" placeholder="请输入费用合计"></el-input>
          </el-form-item>
          <el-form-item label="赠送">
            <el-input v-model="sjyForm.presented" placeholder="请输入赠送"></el-input>
          </el-form-item>
          <el-form-item label="是否分期">
            <el-radio-group v-model="sjyForm.installment">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="月租模式">
            <el-radio-group v-model="sjyForm.whole">
              <el-radio :label="1">30天模式</el-radio>
              <el-radio :label="2">自然月模式</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="sjyForm.remarke" placeholder="备注"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="updateVisible = false">取 消</el-button>
          <el-button type="primary" @click="updateSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 双击弹出框 -->
    <el-dialog title="审批 & 进度" :visible.sync="dblclickVisible">
      <el-descriptions title="合同信息" :column="3" border v-if="currentRow">
        <el-descriptions-item label="公司名称">
          <div>{{ currentRow.companyName }}</div>
          <br>
          <i class="el-icon-search"></i>
          <el-link type="success" @click="queryGSname(v)" v-for="(v, i) in this.currentRow.nameArr" :key="i"
            style="margin-right: 6px;">{{ v
            }}</el-link>
        </el-descriptions-item>
        <el-descriptions-item label="行业类型">
          <!-- <el-tag size="mini" type="warning"> -->
          {{ currentRow.type }}
          <!-- </el-tag> -->
        </el-descriptions-item>
        <el-descriptions-item label="创建人">{{
          currentRow.createUserName
        }}</el-descriptions-item>

        <el-descriptions-item label="联系人">{{
          currentRow.phone
        }}</el-descriptions-item>
        <el-descriptions-item label="电话">{{
          currentRow.telephone
        }}</el-descriptions-item><el-descriptions-item label="产品类型">{{
  currentRow.businessHandling
}}</el-descriptions-item><el-descriptions-item label="业务类型">{{
  currentRow.businessType
}}</el-descriptions-item><el-descriptions-item label="线路类型">{{
  currentRow.routeType
}}</el-descriptions-item><el-descriptions-item label="号码费">{{
  currentRow.numberFee
}}</el-descriptions-item><el-descriptions-item label="号码数量">{{
  currentRow.numberNum
}}</el-descriptions-item><el-descriptions-item label="套餐(月)">{{
  currentRow.combo
}}</el-descriptions-item><el-descriptions-item label="坐席数量">{{
  currentRow.seatsNumber
}}</el-descriptions-item><el-descriptions-item label="套餐内费率">{{
  currentRow.rateWithin
}}</el-descriptions-item><el-descriptions-item label="套餐外费率">{{
  currentRow.rateExtra
}}</el-descriptions-item><el-descriptions-item label="套餐外充值">{{
  currentRow.comboRecharge
}}</el-descriptions-item><el-descriptions-item label="合作周期(月)">{{
  currentRow.cooperationTime
}}</el-descriptions-item><el-descriptions-item label="功能费">{{
  currentRow.featureFee
}}</el-descriptions-item><el-descriptions-item label="费用合计">{{
  currentRow.feeTotal
}}</el-descriptions-item><el-descriptions-item label="赠送">{{
  currentRow.presented
}}</el-descriptions-item><el-descriptions-item label="是否分期">{{
  currentRow.installment
}}</el-descriptions-item><el-descriptions-item label="月租模式">{{
  currentRow.whole == 1
  ? "30天模式"
  : currentRow.whole == 2
    ? "自然月模式"
    : ""
}}</el-descriptions-item><el-descriptions-item label="创建时间">{{
  currentRow.createTime
}}</el-descriptions-item>
        <el-descriptions-item label="审核状态" :contentStyle="{ 'text-align': 'center' }"><el-tag size="mini"
            :type="setType(currentRow.auditStatus)">{{
              currentRow.auditStatus | setStatus
            }}</el-tag></el-descriptions-item>

        <el-descriptions-item label="备注">{{
          currentRow.remarke
        }}</el-descriptions-item>
      </el-descriptions>
      <hr>
      <el-timeline>
        <el-timeline-item v-for="v in planData" :key="v.id" :icon="colorPlan(v.status)">
          <div :class="planClass(v.status)" class="plan-status">
            <h4>{{ v.orderTypeName }}</h4>
            <p>{{ planStatus(v.status) }}</p>
            <p>{{ v.approverDate }}</p>
            <div v-if="v.status == 2 && planId == getId">
              <el-radio v-model="radio" label="3">通过</el-radio>
              <el-radio v-model="radio" label="4">驳回</el-radio>
            </div>
            <el-input type="textarea" :rows="1" placeholder="备注" v-model="auditRemark"
              v-if="v.status == 2 && planId == getId">
            </el-input>
            <el-input type="textarea" :rows="1" placeholder="备注" v-model="v.auditRemark" v-else disabled>
            </el-input>
          </div>
        </el-timeline-item>
      </el-timeline>
      <div class="btns">
        <!-- <el-button type="success" @click="productSearch" style="width: 49%"
          >点击查看产品信息</el-button
        > -->
        <el-button type="primary" @click="planSubmit" style="width: 100%">确定</el-button>
      </div>
    </el-dialog>

    <!-- 模糊查询公司列表 -->
    <el-dialog title="相似公司" :visible.sync="operationVisible" width="30%" custom-class="leftClass" :modal="false">
      <el-descriptions direction="vertical" :column="1" border>
        <el-descriptions-item label="公司名称">
          <div style="height: 500px;overflow-y: scroll;">
            <div v-for="(v, i) in companyNameArr" :key="i" style="font-weight: bold;margin-bottom: 6px;">{{ v }}</div>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  shangjiContractShangjiUpdate,
  shangjiContractShangjiRemindContract,
  shangjiContractShangjiSelectContract,
  shangjiContractShangjiUpdateContract,
  GSFindName
} from "@/api/shangjiContract";
export default {
  data() {
    return {
      operationVisible: false,
      circuitTypeList: [
        {
          id: 0,
          title: "移动回呼",
        },
        {
          id: 1,
          title: "电信回呼",
        },
        {
          id: 2,
          title: "AXB",
        },
        {
          id: 3,
          title: "SIP",
        },
      ],
      businessTypeList: [
        {
          id: 0,
          title: "新开户",
        },
        {
          id: 1,
          title: "加坐席",
        },
        {
          id: 2,
          title: "续费",
        },
        {
          id: 3,
          title: "测试",
        },
      ],
      productTypeList: [
        {
          id: 0,
          title: "SAAS",
        },
        {
          id: 1,
          title: "PASS",
        },
        {
          id: 2,
          title: "部署",
        },
      ],
      typeList: [
        {
          id: 0,
          title: "外卖/生鲜/商超/电商订单配送",
        },
        {
          id: 1,
          title: "快递/物流派送",
        },
        {
          id: 2,
          title: "家政回访服务",
        },
        {
          id: 3,
          title: "会员关怀/居民服务回访/市场问卷调研/民生保障服务",
        },
        {
          id: 4,
          title: "出行服务",
        },
        {
          id: 5,
          title: "办公协作",
        },
        {
          id: 6,
          title: "政采对接",
        },
        {
          id: 7,
          title: "订单类售后服务",
        },
        {
          id: 8,
          title: "法律服务",
        },
        {
          id: 9,
          title: "广告传媒",
        },
        {
          id: 10,
          title: "生物科技",
        },
        {
          id: 11,
          title: "科研技术类",
        },
        {
          id: 12,
          title: "农林牧渔",
        },
        {
          id: 13,
          title: "包装材料类",
        },
        {
          id: 14,
          title: "服装服饰类",
        },
        {
          id: 15,
          title: "化工制造类/推广",
        },
        {
          id: 16,
          title: "一手交通运输工具类",
        },
        {
          id: 17,
          title: "软/硬件服务类",
        },
        {
          id: 18,
          title: "机械机电设备类",
        },
        {
          id: 19,
          title: "汽车维修/车检/保养等",
        },
        {
          id: 20,
          title: "电商回访类",
        },
        {
          id: 21,
          title: "人力资源回访类",
        },
        {
          id: 22,
          title: "少儿素质教育类",
        },
        {
          id: 23,
          title: "生活摄影",
        },
        {
          id: 24,
          title: "品牌招商加盟",
        },
        {
          id: 25,
          title: "园区招商",
        },
        {
          id: 26,
          title: "网站建设/网站推广",
        },
        {
          id: 27,
          title: "再生资源回收",
        },
        {
          id: 28,
          title: "体育用品",
        },
        {
          id: 29,
          title: "办公文教",
        },
        {
          id: 30,
          title: "日用百货销售",
        },
        {
          id: 31,
          title: "人身保险/车险续保",
        },
        {
          id: 32,
          title: "非房屋租赁服务",
        },
        {
          id: 33,
          title:
            "头部外卖平台/网约车平台/线上商超平台/引擎搜索平台/电商平台/音视频平台/新媒体平台/保险平台自有业务服务",
        },
        {
          id: 34,
          title: "家装建材",
        },
        {
          id: 35,
          title: "财税服务",
        },
        {
          id: 36,
          title: "知识产权",
        },
        {
          id: 37,
          title: "工商代办",
        },
        {
          id: 38,
          title: "平台引流/入驻",
        },
        {
          id: 39,
          title: "成人教育/培训类",
        },
        {
          id: 40,
          title: "房地产",
        },
        {
          id: 41,
          title: "会展/展览",
        },
        {
          id: 42,
          title: "学校招生（直招）",
        },
        {
          id: 43,
          title: "二手车/房推广",
        },
        {
          id: 44,
          title: "商铺推广",
        },
        {
          id: 45,
          title: "旅游服务",
        },
        {
          id: 46,
          title: "酒店民宿",
        },
        {
          id: 47,
          title: "K12教育培训/招生",
        },
        {
          id: 48,
          title: "少儿类兴趣培训",
        },
        {
          id: 49,
          title: "资质代办",
        },
        {
          id: 50,
          title: "钢材招商",
        },
        {
          id: 51,
          title: "企业培训",
        },
        {
          id: 52,
          title: "网站推广/加盟",
        },
        {
          id: 53,
          title: "职称评级",
        },
        {
          id: 54,
          title: "餐饮招商",
        },
      ],
      sjyForm: {},
      currentRow: {},
      discountPriceList: [],
      cpData: [],
      auditRemark: null,
      radio: null,
      disabled: false,
      planData: [],
      dblclickVisible: false,
      // // // // // // // // //
      beginTime: null,
      companyNameArr: [],
      clientNameList: [],
      userList: [],
      contractForm: {},
      auditRemark: null,
      radio: null,
      disabled: false,
      planData: [],
      dblclickVisible: false,
      contractId: null,
      beginTime: null,
      contractMoney: null,
      clientUser: null,
      companyUser: null,
      clientName: null,
      contractName: null,
      contractNumber: null,
      tableData: [], //表格内绑定的数据
      updateVisible: false,
    };
  },
  computed: {

  },
  created() {
    this.getId = localStorage.getItem("id");
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.value = localStorage.getItem("status") || "";
    this.getList();
  },
  watch: {
    value() {
      this.getList();
    },
  },
  filters: {
    setStatus(data) {
      if (data == 1) {
        return "审核中";
      } else if (data == 2) {
        return "审核成功";
      } else if (data == 3) {
        return "审核失败";
      }
    },
  },
  methods: {
    // 公司名称模糊查询
    async queryGSname(companyName) {
      let params = {
        companyName
      };
      let res = await GSFindName(params);
      console.log("res", res.data);
      if (res.data.statusCode == '00000') {
        this.companyNameArr = res.data.data
      }
      this.operationVisible = true
    },
    // 编辑确定
    updateSubmit() {
      this.updateVisible = false
      if (this.sjyForm.installment) {
        if (this.sjyForm.installment == "1") {
          this.sjyInstallment = "是";
        } else {
          this.sjyInstallment = "否";
        }
      }
      let params = {
        installment: this.sjyInstallment,
        contractId: this.contractId,
        contractNumber: this.contractNumber,
        ...this.sjyForm,
      };
      // console.log("params", params);
      let res = shangjiContractShangjiUpdate(params);
      res.then((res) => {
        if (res.data.statusCode == "00000") {
          Message.success(res.data.message);
          this.getList();
        } else {
          Message.error(res.data.message);
          this.getList();
        }
      });
    },
    // 编辑
    update(row) {
      this.sjyForm = { ...row };
      this.contractId = row.id;
      this.contractNumber = row.contractNumber;
      this.updateVisible = true;
    },
    setType(data) {
      if (data == 1) {
        return "info";
      } else if (data == 2) {
        return "success";
      } else if (data == 3) {
        return "danger";
      }
    },
    // 审批修改状态确定
    planSubmit() {
      let params = {
        workOrderTypeId: this.workOrderTypeId,
        tableId: this.tableId,
        status: this.radio,
        auditRemark: this.auditRemark,
        contractId: this.contractId,
      };
      // console.log("params", params);
      let res = shangjiContractShangjiUpdateContract(params);
      res.then((res) => {
        Message.success(res.data);
        this.dblclickVisible = false;
        this.getList();
      });
    },
    // 双击事件
    dblclick(row) {
      this.workOrderTypeId = row.contractNumber;
      this.contractId = row.id;
      this.currentRow = row;
      this.currentRow.nameArr = [];
      if ((this.currentRow.companyName + '').length >= 3) {
        const originalString = this.currentRow.companyName;
        const stringLength = originalString.length;
        const partLength = Math.ceil(stringLength / 3);

        for (let i = 0; i < stringLength; i += partLength) {
          const part = originalString.slice(i, i + partLength);
          this.currentRow.nameArr.push(part);
        }

      } else {
        this.currentRow.nameArr.push(this.currentRow.companyName)
      }

      this.dblclickVisible = true;
      let params = {
        contractNumber: this.workOrderTypeId,
      };
      let res = shangjiContractShangjiSelectContract(params);
      res.then((res) => {
        this.planData = res.data.data;
        console.log("res", this.planData);
        res.data.data.forEach((v) => {
          if (v.status == 2) {
            this.tableId = v.id;
            this.planId = v.userId;
          }
        });
      });
    },
    // 审批人动态渲染
    planStatus(value) {
      if (value == 1) {
        return "待审批";
      } else if (value == 2) {
        return "审核中";
      } else if (value == 3) {
        return "审批通过";
      } else if (value == 4) {
        return "审批未通过";
      }
    },
    colorPlan(value) {
      // console.log(112233, value);
      if (value == 1) {
        return "el-icon-bottom";
      } else if (value == 2) {
        return "el-icon-bottom";
      } else if (value == 3) {
        return "el-icon-circle-check";
      } else if (value == 4) {
        return "el-icon-circle-close";
      }
    },
    planClass(value) {
      if (value == 1) {
        return "plan-status1";
      } else if (value == 2) {
        return "plan-status2";
      } else if (value == 3) {
        return "plan-status3";
      } else if (value == 4) {
        return "plan-status4";
      }
    },

    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 展示
    getList() {
      let params = {};
      let res = shangjiContractShangjiRemindContract(params);
      res.then((res) => {
        this.tableData = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
#contract {
  /deep/.leftClass {
    left: 30% !important;

    .el-dialog__title {
      color: #67c23a;
    }
  }

  header {
    h1 {
      font-weight: normal;
    }

    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;

      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;

        .left-wrap {
          display: flex;
          align-items: center;

          span {
            margin: 10px;
            width: 45%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }

  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }

  .fstatusn {
    color: #909399;
  }

  .fstatus0 {
    color: #e6a23c;
  }

  .fstatus1 {
    color: #67c23a;
  }

  .fstatus2 {
    color: #f56c6c;
  }

  .plan-status {
    padding: 20px;
    box-sizing: border-box;
    border-radius: 3px;
    line-height: 30px;
  }

  .plan-status1 {
    background-color: #e9e9eb;
  }

  .plan-status2 {
    background-color: #b3d8ff;
  }

  .plan-status3 {
    background-color: #e1f3d8;
  }

  .plan-status4 {
    background-color: #fde2e2;
  }

  .csr {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .btns {
    // text-align: right;
    display: flex;
  }

  .contract {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 10px;
  }
}
</style>