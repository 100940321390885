<template>
  <el-card id="contract">
    <header>
      <!-- <h1>合同管理--新版本</h1> -->
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span>合同金额</span>
            <el-input
              v-model="contractMoney"
              placeholder="请输入合同金额"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>客户签约人</span>
            <el-input
              v-model="clientUser"
              placeholder="请输入客户签约人"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>公司签约人</span>
            <el-input
              v-model="companyUser"
              placeholder="请输入公司签约人"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>客户名称</span>
            <el-input
              v-model="clientName"
              placeholder="请输入客户名称"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>合同名称</span>
            <el-input
              v-model="contractName"
              placeholder="请输入合同名称"
              size="mini"
            ></el-input>
          </div>
          <!-- <div class="left-wrap">
            <span style="width: 16%">创建时间</span>
            <el-date-picker
              v-model="beginTime"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="mini"
            >
            </el-date-picker>
          </div> -->
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="mini"
            class="searchBtn"
            >查找</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            size="mini"
            class="reset"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <hr>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @row-dblclick="dblclick"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="contractNumber"
          label="合同编号"
          align="center"
        ></el-table-column>
        <!-- <el-table-column
          prop="companyId"
          label="公司ID"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="clientId"
          label="客户ID"
          align="center"
        ></el-table-column> -->
        <!-- <el-table-column
          prop="companyUserId"
          label="公司签约人ID"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="productId"
          label="产品ID"
          align="center"
        ></el-table-column> -->
        <el-table-column
          prop="createUser"
          label="创建人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="contractName"
          label="合同名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="clientName"
          label="客户名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="clientUser"
          label="客户签约人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="companyUser"
          label="公司签约人"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="contractMoney"
          label="合同金额"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="orderDate"
          label="下单时间"
          align="center"
          width="200"
        >
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.orderDate) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="创建时间"
          align="center"
          width="200"
        >
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.createDate) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="beginDate"
          label="开始时间"
          align="center"
          width="200"
        >
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.beginDate) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="endDate"
          label="结束时间"
          align="center"
          width="200"
        >
          <template v-slot="scope">
            <div>
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.endDate) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="remark"
          label="备注"
          align="center"
        ></el-table-column>

        <el-table-column prop="auditStatus" label="状态" align="center">
          <template #default="scope">
            <span v-if="scope.row.auditStatus == 1">审核中</span>
            <span v-if="scope.row.auditStatus == 2">审核通过</span>
            <span v-if="scope.row.auditStatus == 3">审核未通过</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="110" align="center" fixed="right">
          <template #default="scope">
            <el-button type="primary" @click="update(scope)" size="mini"
              >合同详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </main>

    <!-- 双击弹出框 -->
    <el-dialog title="审批 & 进度" :visible.sync="dblclickVisible">
      <el-descriptions title="合同信息" :column="3" border v-if="currentRow">
        <el-descriptions-item label="合同名称">{{
          currentRow.contractName
        }}</el-descriptions-item>
        <el-descriptions-item label="合同编号">
          <el-tag size="mini" type="warning">{{
            currentRow.contractNumber
          }}</el-tag></el-descriptions-item
        >
        <el-descriptions-item label="客户名称">{{
          currentRow.clientName
        }}</el-descriptions-item>
        <el-descriptions-item label="客户签约人">{{
          currentRow.clientUser
        }}</el-descriptions-item>
        <el-descriptions-item label="公司签约人">{{
          currentRow.companyUser
        }}</el-descriptions-item>
        <el-descriptions-item label="合同金额"
          >￥{{ currentRow.contractMoney }}</el-descriptions-item
        >
        <el-descriptions-item
          label="审核状态"
          :contentStyle="{ 'text-align': 'center' }"
          ><el-tag size="mini" :type="setType(currentRow.auditStatus)">{{
            currentRow.auditStatus | setStatus
          }}</el-tag></el-descriptions-item
        >
        <el-descriptions-item label="签约时间">{{
          currentRow.orderDate
        }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{
          currentRow.createDate
        }}</el-descriptions-item>
        <el-descriptions-item label="合同备注">
          {{ currentRow.remark }}
        </el-descriptions-item>
      </el-descriptions>
      <hr>
      <el-timeline>
        <el-timeline-item
          v-for="v in planData"
          :key="v.id"
          :icon="colorPlan(v.status)"
        >
          <div :class="planClass(v.status)" class="plan-status">
            <h4>{{ v.orderTypeName }}</h4>
            <p>{{ planStatus(v.status) }}</p>
            <p>{{ v.approverDate }}</p>
            <div v-if="v.status == 2 && planId == getId">
              <el-radio v-model="radio" label="3">通过</el-radio>
              <el-radio v-model="radio" label="4">驳回</el-radio>
            </div>
            <el-input
              type="textarea"
              :rows="1"
              placeholder="备注"
              v-model="auditRemark"
              v-if="v.status == 2 && planId == getId"
            >
            </el-input>
            <el-input
              type="textarea"
              :rows="1"
              placeholder="备注"
              v-model="v.auditRemark"
              v-else
              disabled
            >
            </el-input>
          </div>
        </el-timeline-item>
      </el-timeline>
      <div class="btns">
        <!-- <el-button type="success" @click="productSearch" style="width: 49%"
          >点击查看产品信息</el-button
        > -->
        <el-button type="primary" @click="planSubmit" style="width: 100%"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!-- 编辑合同弹出框 -->
    <el-dialog title="编辑合同" :visible.sync="updateVisible" width="70%">
      <el-form ref="contractForm" :model="contractForm" label-width="100px">
        <div class="contract">
          <el-form-item label="合同名称">
            <el-input
              v-model="contractForm.contractName"
              placeholder="请输入合同名称"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="客户名称">
            <el-select
              v-model="contractForm.clientName"
              placeholder="请选择客户名称"
              @change="$forceUpdate()"
              filterable
              disabled
            >
              <el-option
                v-for="(item, i) in clientNameList"
                :key="item.id"
                :label="item.realname"
                :value="i"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="合同金额">
            <el-input-number
              v-model="contractForm.contractMoney"
              :precision="1"
              :step="1"
              :max="99999999"
              disabled
            ></el-input-number>
          </el-form-item>
          <el-form-item label="订单时间">
            <el-date-picker
              v-model="contractForm.orderDate"
              type="datetime"
              placeholder="选择订单时间"
              disabled
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="开始时间">
            <el-date-picker
              v-model="contractForm.beginDate"
              type="datetime"
              placeholder="选择开始时间"
              disabled
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间">
            <el-date-picker
              v-model="contractForm.endDate"
              type="datetime"
              placeholder="选择结束时间"
              disabled
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="公司签约人">
            <el-select
              v-model="contractForm.companyUser"
              placeholder="请选择签约人"
              filterable
              disabled
            >
              <el-option
                v-for="(item, i) in userList"
                :key="item.id"
                :label="item.realname"
                :value="i"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="contractForm.remark"
            placeholder="备注"
            disabled
          ></el-input>
        </el-form-item>
        <!-- 从产品列表代入 售价&数量&折扣 -->
        <!-- 产品 -->
        <el-table
          :data="cpData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            prop="productName"
            label="产品名字"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="standardPrice"
            label="产品价格"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="standardPrice"
            label="标准价格"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="unit"
            label="单位"
            align="center"
          ></el-table-column>
          <el-table-column prop="sellingPrice" label="售价" align="center">
            <template #default="scope">
              <el-input
                type="number"
                v-model="scope.row.sellingPrice"
                disabled
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="discountPrice" label="折扣" align="center">
            <template #default="scope">
              <el-select
                v-model="scope.row.discountPrice"
                placeholder="请选择折扣"
                filterable
                disabled
              >
                <el-option
                  v-for="item in discountPriceList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="num" label="数量" align="center">
            <template #default="scope">
              <el-input
                type="number"
                v-model="scope.row.num"
                disabled
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="sumMoney"
            label="合计"
            align="center"
            :formatter="formatter"
          >
          </el-table-column>
        </el-table>
      </el-form>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  contractRemindContract,
  contractSelectContractWorkOrderType,
  contractUpdateContractOrderType,
  contractSelectContractPrice,
} from "@/api/contract";
export default {
  data() {
    return {
      discountPriceList: [],
      cpData: [],
      auditRemark: null,
      radio: null,
      disabled: false,
      planData: [],
      dblclickVisible: false,
      // // // // // // // // //
      clientNameList: [],
      userList: [],
      contractForm: {},
      updateVisible: false,
      auditRemark: null,
      radio: null,
      disabled: false,
      planData: [],
      dblclickVisible: false,

      beginTime: null,
      contractMoney: null,
      clientUser: null,
      companyUser: null,
      clientName: null,
      currentRow: null,
      contractName: null,
      tableData: [], //表格内绑定的数据
    };
  },
  filters: {
    setStatus(data) {
      if (data == 1) {
        return "审核中";
      } else if (data == 2) {
        return "审核成功";
      } else if (data == 3) {
        return "审核失败";
      }
    },
  },
  created() {
    this.getId = localStorage.getItem("id");

    this.beginTime = [this.formatDate(new Date()).substring(0, 10) + " 00:00:00",this.formatDate(new Date()).substring(0, 10) + " 23:59:59"]
    // this.value = [new Date(rootDate + " 00:00:00"), new Date(rootDate + " 23:59:59")]
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.value = localStorage.getItem("status") || "";
    this.getList();
  },
  watch: {
    value() {
      this.getList();
    },
  },
  methods: {
    formatDate(date) {
      var date = new Date(date);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    setType(data) {
      if (data == 1) {
        return "info";
      } else if (data == 2) {
        return "success";
      } else if (data == 3) {
        return "danger";
      }
    },
    // 编辑
    update(row) {
      this.contractForm = { ...row.row };
      this.updateVisible = true;
      let params = {
        contractNumber: row.row.contractNumber,
      };
      let res = contractSelectContractPrice(params);
      res.then((res) => {
        console.log("表格数据", res);
        this.cpData = res.data.data;
      });
    },

    // 审批修改状态确定
    planSubmit() {
      let params = {
        workOrderTypeId: this.workOrderTypeId,
        tableId: this.tableId,
        status: this.radio,
        auditRemark: this.auditRemark,
        contractId: this.contractId,
      };
      // console.log("params", params);
      let res = contractUpdateContractOrderType(params);
      res.then((res) => {
        Message.success(res.data);
        this.dblclickVisible = false;
        this.getList();
      });
    },
    // 双击事件
    dblclick(row) {
      this.currentRow = row;
      this.workOrderTypeId = row.contractNumber;
      this.contractId = row.id;
      this.dblclickVisible = true;
      let params = {
        contractNumber: this.workOrderTypeId,
      };
      let res = contractSelectContractWorkOrderType(params);
      res.then((res) => {
        this.planData = res.data.data;
        console.log("res", this.planData);
        res.data.data.forEach((v) => {
          if (v.status == 2) {
            this.tableId = v.id;
            this.planId = v.userId;
          }
        });
      });
    },
    // 审批人动态渲染
    planStatus(value) {
      if (value == 1) {
        return "待审批";
      } else if (value == 2) {
        return "审核中";
      } else if (value == 3) {
        return "审批通过";
      } else if (value == 4) {
        return "审批未通过";
      }
    },
    colorPlan(value) {
      // console.log(112233, value);
      if (value == 1) {
        return "el-icon-bottom";
      } else if (value == 2) {
        return "el-icon-bottom";
      } else if (value == 3) {
        return "el-icon-circle-check";
      } else if (value == 4) {
        return "el-icon-circle-close";
      }
    },
    planClass(value) {
      if (value == 1) {
        return "plan-status1";
      } else if (value == 2) {
        return "plan-status2";
      } else if (value == 3) {
        return "plan-status3";
      } else if (value == 4) {
        return "plan-status4";
      }
    },

    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    //重置刷新按钮
    reset() {
      this.contractMoney = null;
      this.clientUser = null;
      this.companyUser = null;
      this.clientName = null;
      this.beginTime = null;
      this.contractName = null;
      this.getList();
    },
    //点击查找
    handleSearch() {
      this.getList();
    },
    // 展示
    getList() {
      let params = {
        beginDate: this.beginTime
          ? this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.beginTime[0])
          : null,
        endDate: this.beginTime
          ? this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.beginTime[1])
          : null,
        contractMoney: this.contractMoney,
        clientUser: this.clientUser,
        companyUser: this.companyUser,
        clientName: this.clientName,
        contractName: this.contractName,
      };
      let res = contractRemindContract(params);
      res.then((res) => {
        this.tableData = res.data.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-button--success,
.el-button--primary {
  color: #fff;
  background-color: #3278FE;
  border-color: #3278FE;
  &:hover {
    opacity: 0.7;
  }
}
#contract {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            width: 45%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
  .fstatusn {
    color: #909399;
  }
  .fstatus0 {
    color: #e6a23c;
  }
  .fstatus1 {
    color: #67c23a;
  }
  .fstatus2 {
    color: #f56c6c;
  }
  .plan-status {
    padding: 20px;
    box-sizing: border-box;
    border-radius: 3px;
    line-height: 30px;
  }
  .plan-status1 {
    background-color: #e9e9eb;
  }
  .plan-status2 {
    background-color: #b3d8ff;
  }
  .plan-status3 {
    background-color: #e1f3d8;
  }
  .plan-status4 {
    background-color: #fde2e2;
  }
  .csr {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .btns {
    // text-align: right;
    display: flex;
  }
  .contract {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 10px;
  }
}
</style>