<template>
  <el-card id="audit">
    <header>
      <h2 style="display: flex;">
        <span style="width: 80px;line-height: 30px;">合同管理</span><span
          v-if="showTitleText">(可管理自己添加的合同信息、以及查看审批合同、待审批合同。<span
            style="color: red">注意：如需要合同审批流程，需和总部客服沟通，由总部完成配置方可使用</span>)</span>
        <el-tooltip class="item" effect="dark" content="点击查看详情说明" placement="top">
          <img v-if="!showTitleText" src="@/assets/images/what.png" alt=""
            style="width: 30px; height: 30px;cursor: pointer;" @click="showTitleText = true" />
        </el-tooltip>
      </h2>
    </header>
    <!-- <hr> -->
    <hr>
    <main>
      <contract v-if="ifParentId != 7" />
      <sjyContract v-else />
      <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="新版合同管理" name="contract">
        </el-tab-pane>
        <el-tab-pane label="老版合同管理" name="old">
          <old />
        </el-tab-pane>
      </el-tabs> -->
    </main>
  </el-card>
</template>

<script>
import contract from "@/views/audit/contract.vue";
import sjyContract from "@/views/audit/sjyContract.vue";
// import old from "@/views/audit/old.vue";

export default {
  components: { contract, sjyContract },
  data() {
    return {
      showTitleText: false,
      ifParentId: null,
      activeName: "contract",
    };
  },
  created() {
    this.ifParentId = localStorage.getItem("parentId");
  },
};
</script>

<style lang="less" scoped>
#audit {
  height: 100%;
  overflow-y: scroll;

  header {
    h1 {
      font-weight: normal;

      >span {
        font-size: 18px;
        color: #606369;
      }
    }
  }
}
</style>